import { IState } from '../../../../store';

export function isSingleGalleryCountSelector(state: IState) {
  const videos = state.offerData.offer.videos || [];
  const photoCount = photoCountSelector(state);

  return videos.length + photoCount <= 1;
}

export function layoutPositionIdSelector(state: IState) {
  const photos = state.offerData.offer.photos || [];
  const index = photos.findIndex(photo => photo.isLayout);

  return index >= 0 ? index : undefined;
}

export function photoCountSelector(state: IState) {
  const { offerData } = state;
  const photos = offerData.offer.photos || [];

  return photos.length;
}

export function isCianLayoutSelector(state: IState): boolean {
  const photos = state.offerData.offer.photos || [];
  const layout = photos.find(photo => photo.isLayout);

  return Boolean(layout && layout.isCianLayout);
}

export function isVideoSelector(state: IState) {
  const videos = state.offerData.offer.videos || [];

  return videos.length > 0;
}
