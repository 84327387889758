import * as React from 'react';
import { useSelector } from 'react-redux';

import { useApplicationContext } from 'shared/containers/ApplicationContext';
import { useDailyrentCheckoutPageUrl } from 'shared/hooks/useDailyrentCheckoutPageUrl';
import { useRenderContactButtons } from 'shared/hooks/useRenderContactButtons';
import { selectDailyrentActionType } from 'shared/selectors/dailyrentActionType';
import { selectExternalTour } from 'shared/selectors/offerData/tour/selectExternalTour';
import { selectExternalTourBannerImageUrl } from 'shared/selectors/offerData/tour/selectExternalTourBannerImageUrl';
import { EActionType } from 'shared/types/dailyrentActionButtonsType';

import { MAP_VIDEO_TYPE_TO_MEDIA_TYPE } from './constants';
import { trackFullscreenOpen } from './tracking';
import { useMortgageBanner } from './utils/useMortgageBanner';
import { EMediaType, Gallery, TMedia } from '../../components/Gallery';
import THUMB_SRC from '../../components/offer_gallery/createMortgageFrame/img/thumb.svg';
import { FullScreenControlsContainer } from '../../components/offer_gallery/fotorama/full_screen_controls/container';
import { selectOffer } from '../../selectors';

interface IGalleryContainerProps {
  phoneButton?: boolean;
  favoritesButton?: boolean;
}

export const GalleryContainer: React.FC<React.PropsWithChildren<IGalleryContainerProps>> = ({
  phoneButton = true,
  favoritesButton = true,
}) => {
  const { config } = useApplicationContext();
  const isFullscreenRedesign = !!config.get<boolean>('OfferCardGalleryFullscreenRedesign.Enabled');
  const { photos, videos } = useSelector(selectOffer);
  const externalTourKey = useSelector(selectExternalTour);
  const externalTourBannerImageUrl = useSelector(selectExternalTourBannerImageUrl);

  const dailyrentCheckoutPageUrl = useDailyrentCheckoutPageUrl();
  const actionType = useSelector(selectDailyrentActionType);
  const showDailyrentBookOnlineButton = actionType === EActionType.OtaBooking;
  const { showPhoneButton } = useRenderContactButtons();

  const mortgageBanner = useMortgageBanner(THUMB_SRC);

  const media: TMedia[] = React.useMemo(() => {
    const media: TMedia[] = [];

    if (videos) {
      media.push(
        ...videos.map<TMedia>(
          ({ type, url: src, previewUrl = '', id }) =>
            ({
              type: MAP_VIDEO_TYPE_TO_MEDIA_TYPE[type],
              previewUrl,
              src,
              id,
            }) as TMedia,
        ),
      );
    }

    if (photos) {
      const photoMedias = [...photos]
        .sort((a, b) => (a.isDefault === b.isDefault ? 0 : a.isDefault ? -1 : 1))
        .map<TMedia>(p => ({
          type: p.isLayout ? EMediaType.Layout : EMediaType.Photo,
          src: p.fullUrl,
          thumbnailSrc: p.thumbnailUrl,
          description: p.description,
        }));

      media.push(...photoMedias);
    }

    if (externalTourKey && externalTourBannerImageUrl) {
      media.push({
        type: EMediaType.Tour,
        thumbnailSrc: externalTourBannerImageUrl,
      });
    }

    if (mortgageBanner) {
      media.push(mortgageBanner);
    }

    return media;
  }, [mortgageBanner, photos, videos, externalTourBannerImageUrl, externalTourKey]);

  return (
    <Gallery
      isFullscreenRedesign={isFullscreenRedesign}
      slotFullscreenBefore={
        <FullScreenControlsContainer
          nextSliderCompat
          hideFavoritesButton={!favoritesButton}
          hidePhoneButton={!phoneButton || !showPhoneButton}
          showDailyrentBookOnlineButton={showDailyrentBookOnlineButton}
          dailyrentCheckoutPageUrl={dailyrentCheckoutPageUrl}
        />
      }
      media={media}
      onFullscreenOpen={trackFullscreenOpen}
    />
  );
};
