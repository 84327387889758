import * as React from 'react';
import { useSelector } from 'react-redux';

import { Link } from 'shared/components/Link';
import { selectOffer, selectParent } from 'shared/selectors';
import { buildingTypePrepositional, ParentType } from 'shared/utils/offer_helpers';

import { businessShoppingCenterSelector as selectBusinessShoppingCenterSelector } from '../../../../selectors/offerData/offer/businessShoppingCenterSelector';

export function ParentLinkContainer() {
  const businessShoppingCenter = useSelector(selectBusinessShoppingCenterSelector);
  const offer = useSelector(selectOffer);
  const parent = useSelector(selectParent);
  const formatParentName = React.useMemo(() => {
    let prefix = '';

    if (!parent) {
      return undefined;
    }

    if (parent.type === ParentType.JK) {
      prefix = 'ЖК';
    } else if (parent.type === ParentType.KP) {
      prefix = 'посёлке';
    } else if (parent.type === ParentType.Coworking) {
      prefix = 'коворкинге';
    } else {
      if (businessShoppingCenter?.buildingType && buildingTypePrepositional[businessShoppingCenter.buildingType]) {
        prefix = buildingTypePrepositional[businessShoppingCenter.buildingType];
      } else if (offer.building?.type) {
        prefix = buildingTypePrepositional[offer.building.type];
      }
    }

    return `${prefix} «${parent.name}»`;
  }, [parent, offer, businessShoppingCenter]);

  if (!parent || !parent.url || !formatParentName) {
    return null;
  }

  return (
    <Link target="_blank" url={parent.url}>
      {formatParentName}
    </Link>
  );
}
