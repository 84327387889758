import { UIHeading5 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './HintQuestion.css';

interface IHintQuestionProps {
  text: string;
  onClick(text: string): void;
}

export const HintQuestion: React.FC<IHintQuestionProps> = ({ text, onClick }) => {
  return (
    <span className={styles['container']} onClick={() => onClick(text)}>
      <UIHeading5 color="text-main-default" whiteSpace="nowrap">
        {text}
      </UIHeading5>
    </span>
  );
};
