import { ca } from '@cian/analytics';

import { EMediaType } from './types';

const MAP_VIDEO_TYPE_TO_STRING: Partial<Record<EMediaType, string>> = {
  [EMediaType.VideoVk]: 'vk',
  [EMediaType.Video]: 'youtube',
  [EMediaType.VideoKinescope]: 'kinescope',
};

export const trackVideoPlaying = (videoSource: EMediaType) => {
  ca('eventSite', {
    action: `Click_sopr`,
    category: 'gallery',
    label: 'video',
    name: 'oldevent',
    page: {
      extra: {
        video_source: MAP_VIDEO_TYPE_TO_STRING[videoSource],
      },
    },
    useLastProducts: true,
  });
};

export const trackLayoutSlideView = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'gallery',
    action: 'show_sopr',
    label: 'layout',
    useLastProducts: true,
  });
};
