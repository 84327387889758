import { UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './CallToAuthor.css';

interface ICallToAuthorProps {
  phone?: React.ReactElement;
}

export const CallToAuthor = ({ phone }: ICallToAuthorProps) => {
  return (
    <div className={styles['container']}>
      <UIText2>или узнайте подробности по телефону</UIText2>
      <div className={styles['phone']}>{phone}</div>
    </div>
  );
};
