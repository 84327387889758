import * as React from 'react';

import { Tab } from './Tab';
import * as styles from './galleryFullscreenTabs.css';
import { trackLayoutsClick, trackPhotosClick, trackVideosClick } from './tracking';
import { IInfoControlConfig } from './types';
import { useControls } from './utils/useControls';
import { EMediaType, TMedia } from '../../types';

interface IInfoControlsProps {
  media: TMedia[];
  scrollTo(i: number): void;
  currentSlide: number;
}

const GalleryFullscreenTabsComponent: React.FC<React.PropsWithChildren<IInfoControlsProps>> = ({
  media,
  scrollTo,
  currentSlide,
}) => {
  const controls = useControls({ media });

  /* istanbul ignore next */
  const handleControlClick = React.useCallback(
    (config: IInfoControlConfig) => {
      scrollTo(config.firstIndex);

      switch (config.type) {
        case EMediaType.Layout:
          trackLayoutsClick();
          break;
        case EMediaType.Photo:
          trackPhotosClick();
          break;
        case EMediaType.Video:
          trackVideosClick();
          break;
      }
    },
    [scrollTo],
  );

  const isActive = (c: IInfoControlConfig, currentSlide: number) => {
    return currentSlide >= c.firstIndex && currentSlide <= c.lastIndex;
  };

  return React.useMemo(() => {
    /* istanbul ignore next */
    if (!controls) {
      return null;
    }

    return (
      <ul className={styles['container']}>
        {controls.map((c, i) => (
          <Tab
            key={`control_${i}`}
            config={c}
            isActive={isActive(c, currentSlide)}
            onClick={() => handleControlClick(c)}
          />
        ))}
      </ul>
    );
  }, [controls, handleControlClick, currentSlide]);
};

export const GalleryFullscreenTabs = GalleryFullscreenTabsComponent;
