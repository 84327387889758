import { useSlideContext } from '@cian/slider';
import * as React from 'react';

interface ISlideActiveObserverProps {
  callback: (...args: unknown[]) => void;
  skip?: boolean;
  children: React.ReactElement;
}

export const SlideActiveObserver: React.FC<ISlideActiveObserverProps> = ({ callback, children, skip }) => {
  const { isActive } = useSlideContext();

  React.useEffect(() => {
    if (isActive && !skip) {
      callback();
    }
  }, [callback, isActive, skip]);

  return children;
};
