import { UIText1 } from '@cian/ui-kit';
import { numberToPrettyString } from '@cian/utils/lib/shared/number_helpers';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { pricePerSquareSelector } from 'shared/selectors/offerData/pricePerSquareSelector';
import { withNoBreakSpaces } from 'shared/utils/formatters';

export const PricePerSquare = () => {
  const pricePerSquare = useSelector(pricePerSquareSelector);

  if (!pricePerSquare) {
    return null;
  }

  return <UIText1>{withNoBreakSpaces(numberToPrettyString(pricePerSquare) + ' ₽/м²')}</UIText1>;
};
