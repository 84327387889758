/**
 * @todo Удалить директорию с RS
 * @description Данный функционал появился в задаче CD-189160, будет удалён в задаче CD-190741
 * Удалить директорию целиком, так как данные лейблы переходят с галереи в блок над галереей
 */

import * as React from 'react';
import { useSelector } from 'react-redux';

import { MortgagedPropertyLabels } from 'shared/components/MortgagedPropertyLabels';
import { useIsElectronicTradingEnabled } from 'shared/hooks/useIsElectronicTradingOffer';
import { selectAgentLists } from 'shared/selectors/offerData/selectAgentLists';

import { useApplicationContext } from '../ApplicationContext';

export const MortgagedPropertyLabelsContainer: React.FC = () => {
  const agentLists = useSelector(selectAgentLists);
  const { config } = useApplicationContext();
  const mortgagedProperties = config.get<Record<string, string>>('sellingTypes.agentLists');

  /**
   * @todo Удалить RS
   * @description Данный функционал появился в задаче CD-189160, будет удалён в задаче CD-190741
   */
  const isElectronicTradingEnabled = useIsElectronicTradingEnabled();

  const texts = agentLists
    .filter(agentList => {
      /**
       * @todo Удалить ветвление
       * @description Данный функционал появился в задаче CD-189160, будет удалён в задаче CD-190741
       * Удалить блок ветвления полностью
       */
      if (!isElectronicTradingEnabled) {
        return true;
      }

      /**
       * @description Данные лейблы переходят из галереи в лейблы над галереей, из-за этого убираем их из списка
       */
      return agentList !== 'tradeRealty' && agentList !== 'pledgeRealty';
    })
    .map(agentList => mortgagedProperties?.[agentList])
    .filter(Boolean) as string[];

  if (!texts.length) {
    return null;
  }

  return <MortgagedPropertyLabels texts={texts} />;
};
