import { commercialPriceSelector, residentialPriceSelector } from '../../../../selectors/price';
import { IState } from '../../../../store';
import { getOfferType, isCommercial } from '../../../../utils/offer_type';

export const priceSelector = (state: IState) => {
  const {
    offerData: { offer },
    knAuctionCompanies,
  } = state;
  const offerType = getOfferType(offer);
  const isPriceAuction = offer.cianUserId && knAuctionCompanies.includes(offer.cianUserId);

  if (isCommercial(offerType)) {
    if (isPriceAuction) {
      return 'Электронные торги';
    }

    return commercialPriceSelector(state);
  }

  return residentialPriceSelector(state);
};
