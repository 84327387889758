import * as React from 'react';
import { useSelector } from 'react-redux';

import { useIsElectronicTradingOffer } from 'shared/hooks/useIsElectronicTradingOffer';
import { selectIsOfferPublished, selectOffer, selectUser } from 'shared/selectors';
import { offerChatSelector } from 'shared/selectors/offerChat';

import { CallUnderDescriptionBannerContainer } from './CallUnderDescriptionBannerContainer';
import { HintQuestionsContainer } from './HintQuestionsContainer';

export function CallUnderDescriptionContainer() {
  const { isChatsEnabled, hintQuestions } = useSelector(offerChatSelector);
  const offer = useSelector(selectOffer);
  const user = useSelector(selectUser);
  const isOfferPublished = useSelector(selectIsOfferPublished);

  const isElectronicTradingOffer = useIsElectronicTradingOffer();

  if (!isOfferPublished || user?.cianUserId === offer.cianUserId || isElectronicTradingOffer) {
    return null;
  }

  if (isChatsEnabled && hintQuestions.length) {
    return <HintQuestionsContainer />;
  }

  return <CallUnderDescriptionBannerContainer />;
}
