import cx from 'clsx';
import * as React from 'react';

import * as styles from './tab.css';
import { IInfoControlConfig } from './types';

interface IControlProps {
  isActive: boolean;
  config: IInfoControlConfig;
  onClick?(): void;
}

export const Tab: React.FC<React.PropsWithChildren<IControlProps>> = ({ config, isActive, onClick }) => {
  /* istanbul ignore next */
  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();

      if (onClick) {
        onClick();
      }
    },
    [onClick],
  );

  return (
    <li>
      <button
        role="tab"
        aria-selected={isActive}
        data-name="GalleryFullscreenTab"
        className={cx(styles['button'], isActive && styles['button_active'])}
        onClick={handleClick}
      >
        {config.label}
      </button>
    </li>
  );
};
