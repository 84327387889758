import { useEventCallback } from '@cian/react-utils';
import Player, { EventErrorTypes } from '@kinescope/react-kinescope-player';
import * as React from 'react';

import { useApplicationContext } from 'shared/containers/ApplicationContext';

import * as styles from './KinescopePlayer.css';
import { TMediaVideo } from '../../types';

export const KinescopePlayer: React.FC<TMediaVideo> = ({ id }) => {
  const { logger } = useApplicationContext();

  const logError = useEventCallback((error: string) => {
    logger.error('Kinescope player error', {
      error,
    });
  });

  const handlePlayerError = useEventCallback((data: EventErrorTypes) => {
    logError(JSON.stringify(data));
  });

  const handleJSLoadError = useEventCallback(() => {
    logError('JS Load Error');
  });

  return (
    <Player
      videoId={id}
      className={styles['kinescope-player']}
      autoPlay
      onError={handlePlayerError}
      onJSLoadError={handleJSLoadError}
    />
  );
};
