import * as React from 'react';

import { EMediaType, TMedia } from 'shared/components/Gallery';
import { IInfoControlConfig } from 'shared/components/Gallery/components/GalleryFullscreenTabs/types';

/* istanbul ignore next */
export const useControls = ({ media }: { media: TMedia[] }) => {
  return React.useMemo<IInfoControlConfig[] | null>(() => {
    const list: IInfoControlConfig[] = [];

    const videos = media.filter(m =>
      [EMediaType.Video, EMediaType.VideoKinescope, EMediaType.VideoVk].includes(m.type),
    );
    if (videos.length > 0) {
      list.push({
        type: EMediaType.Video,
        label: videos.length === 1 ? 'Видео' : `${videos.length} видео`,
        firstIndex: media.indexOf(videos[0]),
        lastIndex: media.indexOf(videos[videos.length - 1]),
      });
    }

    const layouts = media.filter(m => [EMediaType.Layout, EMediaType.CianLayout].includes(m.type));
    if (layouts.length > 0) {
      list.push({
        type: EMediaType.Layout,
        label: 'Планировка',
        firstIndex: media.indexOf(layouts[0]),
        lastIndex: media.indexOf(layouts[layouts.length - 1]),
      });
    }

    const photos = media.filter(m => m.type === EMediaType.Photo);
    if (photos.length > 0) {
      list.push({
        type: EMediaType.Photo,
        label: photos.length === 1 ? 'Фото' : `${photos.length} фото`,
        firstIndex: media.indexOf(photos[0]),
        lastIndex: media.indexOf(photos[photos.length - 1]),
      });
    }

    const tour = media.filter(m => m.type === EMediaType.Tour);
    if (tour.length > 0) {
      list.push({
        type: EMediaType.Tour,
        label: '3D-тур',
        firstIndex: media.indexOf(tour[0]),
        lastIndex: media.indexOf(tour[tour.length - 1]),
      });
    }

    if (list.length === 0) {
      return null;
    }

    return list;
  }, [media]);
};
