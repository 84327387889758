import { UIHeading2, UIText2 } from '@cian/ui-kit';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './CallUnderDescriptionBanner.css';

interface ICallUnderDescriptionBannerProps {
  withoutButton?: boolean;
  title?: string;
  subtitle?: string;
}

export function CallUnderDescriptionBanner({
  children,
  withoutButton,
  ...textProps
}: React.PropsWithChildren<ICallUnderDescriptionBannerProps>) {
  const { title = 'Позвоните автору', subtitle } = textProps;

  return (
    <div className={cx(styles['container'], withoutButton && styles['container--without-button'])}>
      <div className={styles['title']}>
        <UIHeading2>{title}</UIHeading2>
      </div>
      <div className={styles['subtitle']}>
        <UIText2>{subtitle}</UIText2>
      </div>
      {children}
    </div>
  );
}
