import * as React from 'react';

import * as style from './style.css';

export interface IGallerySidebarLayoutProps {
  title: React.ReactNode;
  favoriteButton: React.ReactNode;
  price: React.ReactNode;
  buttons: React.ReactNode;
  author: React.ReactNode;
  pricePerSquare: React.ReactNode;
}

export const GallerySidebarLayout: React.FC<React.PropsWithChildren<IGallerySidebarLayoutProps>> = ({
  title,
  favoriteButton,
  price,
  buttons,
  author,
  pricePerSquare,
}) => {
  return (
    <div className={style['wrapper']}>
      <div className={style['title']}>
        {title}
        <div className={style['actions']}>{favoriteButton}</div>
      </div>

      {price}
      {pricePerSquare}

      <div className={style['buttons']}>{buttons}</div>
      <div>{author}</div>
    </div>
  );
};
