import * as React from 'react';
import { useSelector } from 'react-redux';

import { PriceAuctionContainer } from 'shared/components/PriceAuction';
import { PriceElectronicTrading } from 'shared/components/PriceElectronicTrading/PriceElectronicTrading';
import { PriceInfo } from 'shared/components/PriceInfo/PriceInfo';
import { CoworkingAdditionalInfo } from 'shared/containers/CoworkingAdditionalInfo/CoworkingAdditionalInfo';
import { isCoworkingSelector } from 'shared/selectors/offerData/offer/isCoworkingSelector';
import { isPriceElectronicTradingSelector } from 'shared/selectors/price';
import { selectPriceAside } from 'shared/selectors/price/selectPriceAside';
import { withNoBreakSpaces } from 'shared/utils/formatters';

export const PriceContainer = () => {
  const price = useSelector(selectPriceAside);
  const isCoworking = useSelector(isCoworkingSelector);
  const isPriceAuction = useSelector(isPriceElectronicTradingSelector);

  if (isPriceAuction) {
    return <PriceElectronicTrading />;
  }

  if (!price) {
    return null;
  }

  return (
    <>
      <PriceAuctionContainer />
      <PriceInfo
        price={withNoBreakSpaces(price)}
        priceHistory={null}
        priceInOtherCurrencies={null}
        coworkingAdditionalInfo={isCoworking ? <CoworkingAdditionalInfo /> : null}
      />
    </>
  );
};
