import * as React from 'react';
import { useSelector } from 'react-redux';

import { Phones } from 'shared/components/HintQuestions/Phones';
import { getPhones } from 'shared/selectors';
import { formatNumber, getPhoneLink } from 'shared/utils/phone';

export const PhonesOpened = () => {
  const phones = useSelector(getPhones);

  const formattedPhones = React.useMemo(
    () =>
      phones.map(phone => ({
        number: formatNumber(phone),
        link: getPhoneLink(phone),
      })),
    [phones],
  );

  if (!phones.length || phones.some(phone => !phone.countryCode || !phone.number)) {
    return null;
  }

  return <Phones phones={formattedPhones} />;
};
