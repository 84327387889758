import { EMediaType } from '../../types';
import { KinescopePlayer } from '../KinescopePlayer';
import { VkPlayer } from '../VkPlayer';
import { YoutubePlayer } from '../YoutubePlayer';

export const MAP_TYPE_TO_PLAYERS = {
  [EMediaType.Video]: YoutubePlayer,
  [EMediaType.VideoKinescope]: KinescopePlayer,
  [EMediaType.VideoVk]: VkPlayer,
};
