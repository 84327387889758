import { connect } from 'react-redux';

import { selectCallButtonText } from 'shared/selectors/callButton';

import {
  FullScreenControls,
  IFullScreenControlsCommonProps,
  IFullScreenControlsDispatchProps,
  IFullScreenControlsOwnProps,
} from './';
import { priceSelector } from './selectors';
import { getNewbuildingId, selectIsOfferWithMultiAds } from '../../../../selectors';
import { getDynamicCalltrackingSiteBlockId } from '../../../../selectors/getDynamicCalltrackingSiteBlockId';
import { isRenterFormEnabled } from '../../../../selectors/isRenterFormEnabled';
import { setPhoneCollapse } from '../../../../store/phone_collapse';

import type { DispatchReduxAction, IState } from '../../../../store';

function mapStateToProps(state: IState, ownProps: IFullScreenControlsOwnProps) {
  const { hidePhoneButton } = ownProps;

  return {
    abGroup: state.abGroup,
    agent: state.offerData.agent,
    kpId: state.offerData.kp && state.offerData.kp.id,
    offer: state.offerData.offer,
    user: state.offerData.user,
    siteBlockId: getDynamicCalltrackingSiteBlockId(state),
    newbuildingId: getNewbuildingId(state),
    isAuthenticated: !!(state.offerData.user && state.offerData.user.isAuthenticated),
    price: priceSelector(state),
    dynamicCalltrackingPlaceType: 'Fotorama',
    hidePhoneButton: isRenterFormEnabled(state) || hidePhoneButton,
    callButtonText: selectCallButtonText(state),
    isOfferMultiAds: selectIsOfferWithMultiAds(state),
  };
}

function mapDispatchToProps(dispatch: DispatchReduxAction) {
  return {
    setPhoneCollapse: () => dispatch(setPhoneCollapse()),
  };
}

export const FullScreenControlsContainer = connect<
  IFullScreenControlsCommonProps,
  IFullScreenControlsDispatchProps,
  IFullScreenControlsOwnProps
>(
  mapStateToProps,
  mapDispatchToProps,
)(FullScreenControls);
