import * as React from 'react';

import * as styles from './MortgageBanner.css';

interface IMortgageBannerProps {
  innerHTML: string;
}

export const MortgageBanner: React.FC<React.PropsWithChildren<IMortgageBannerProps>> = ({ innerHTML }) => {
  // eslint-disable-next-line react/no-danger
  return <div className={styles['container']} dangerouslySetInnerHTML={{ __html: innerHTML }} />;
};
