import { ErrorLogComponent } from '@cian/error-log-component';
import { ILogger } from '@cian/logger/shared';
import * as React from 'react';

import { GalleryControlsContainer } from './controls/container';
import * as styles from './index.css';
import { GalleryContainer } from '../../containers/GalleryContainer';
import { MortgagedPropertyLabelsContainer } from '../../containers/MortgagedPropertyLabelsContainer';
import { CountrysidePromoLabelContainer } from '../CountrysidePromotionsLabel/container';

export interface IOfferGalleryProps {
  reactErrorLogger: ILogger;
  hidePhoneButton?: boolean;
  hideFavoritesButton?: boolean;
}

export class OfferGallery extends React.Component<IOfferGalleryProps, {}> {
  public render() {
    const { reactErrorLogger, hideFavoritesButton, hidePhoneButton } = this.props;

    return (
      <div className={styles['photo_gallery_container']} data-name="OfferGallery" id="photos">
        <ErrorLogComponent logger={reactErrorLogger}>
          <GalleryContainer phoneButton={!hidePhoneButton} favoritesButton={!hideFavoritesButton} />
        </ErrorLogComponent>
        <GalleryControlsContainer />
        <MortgagedPropertyLabelsContainer />
        <CountrysidePromoLabelContainer />
      </div>
    );
  }
}
