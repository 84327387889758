import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FavoriteButton } from 'shared/components/Gallery/components/GallerySidebar';
import { getIsFavorite } from 'shared/selectors';
import { changeFavoriteAction } from 'shared/store/offer';

export const FavoriteButtonContainer: React.FC = () => {
  const dispatch = useDispatch();
  const isFavorite = useSelector(getIsFavorite);
  const changeFavorite = React.useCallback(() => dispatch(changeFavoriteAction()), []);

  return <FavoriteButton checked={isFavorite} onButtonClick={changeFavorite} />;
};
