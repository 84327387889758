import * as React from 'react';

import * as styles from './index.css';

export type TIconType = 'video' | 'layout' | 'photo' | 'cian-layout';

const title: { [index: string]: string } = {
  layout: 'Планировка',
  'cian-layout': 'Планировка от Циан',
  photo: 'фото',
  video: 'Видео',
};

export interface IControlsProps {
  type: TIconType;
  id: number;
  elementsCount: number;
  onButtonClick(id: number): void;
}

export class MenuButton extends React.Component<IControlsProps, {}> {
  public render() {
    return (
      <div
        className={styles['menu_button']}
        onClick={() => {
          this.props.onButtonClick(this.props.id);
        }}
      >
        <div className={styles[`icon-${this.props.type}`]} />
        <div className={styles['title']}>{getTitle(this.props.type, this.props.elementsCount)}</div>
      </div>
    );
  }
}

function getTitle(type: TIconType, count: number) {
  return type === 'photo' ? `${count} ${title[type]}` : title[type];
}
