import cx from 'clsx';
import * as React from 'react';

import { ICountrysidePromoItem } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import * as styles from './PromotionsLabel.css';
import { IAnalyticsData } from '../../types';
import { trackPromotionsLabelClick } from '../../utils/tracking/promotions';

export interface IPromotionsLabelStoreProps {
  promotionItem: ICountrysidePromoItem | null;
  analyticsData: IAnalyticsData;
}

export class PromotionsLabel extends React.Component<IPromotionsLabelStoreProps> {
  public render() {
    const { promotionItem } = this.props;

    if (!promotionItem || !promotionItem.label || promotionItem.label.length > 35) {
      return null;
    }

    return (
      <div
        className={cx(styles['label'], styles[`background-${promotionItem.type}`])}
        onClick={this.onLabelCkick}
        title="Все акции"
      >
        {promotionItem.label}
      </div>
    );
  }

  private onLabelCkick = () => {
    const {
      promotionItem,
      analyticsData: { offer, village },
    } = this.props;
    if (promotionItem && village) {
      trackPromotionsLabelClick(promotionItem.type, offer.id, village.id);
    }

    const promotionList = document.getElementById('promotions');

    if (promotionList) {
      promotionList.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };
}
