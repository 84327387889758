import { ca } from '@cian/analytics';

export function trackVideosClick() {
  ca('event', {
    action: 'tab_video_photo_block',
    category: 'card',
    name: 'oldevent',
  });
}

export function trackLayoutsClick() {
  ca('event', {
    action: 'tab_layout_photo_block',
    category: 'card',
    name: 'oldevent',
  });
}

export function trackPhotosClick() {
  ca('event', { action: 'tab_photos_photo_block', category: 'card', name: 'oldevent' });
}
