import { ca } from '@cian/analytics';

import { EAnalyticsKey } from 'shared/repositories/chats/entities/chat_hint/ChatHintSchema';
import { IOffer } from 'shared/types/offerData';
import { getSourceParams } from 'shared/utils/getSourceParams';
import { getDealTypeSopr } from 'shared/utils/tracking/getDealTypeSopr';

import { getCategory } from '../../utils/tracking/getCategory';

export const trackHintQuestionClick = (offer: IOffer, questionName: EAnalyticsKey) => {
  const category = getCategory(offer);
  const dealType = getDealTypeSopr(offer);
  const sourceParams = getSourceParams();

  ca('eventSite', {
    name: 'oldevent',
    category: 'Messages',
    action: 'click_sopr',
    label: 'short_question',
    products: [
      {
        brand: '',
        category,
        cianId: offer.id,
        dealType,
        hiddenBase: false,
        id: offer.id,
        position: 1,
        price: offer.bargainTerms.price,
        published: true,
        // Всегда передаем 1
        quantity: 1,
        variant: [],
        extra: { questionName },
      },
    ],
    page: {
      pageType: 'Card',
      siteType: 'desktop',
      extra: {
        pageTypeSource: sourceParams?.source,
        listingTypeSource: sourceParams?.listingType,
      },
    },
  });
};
