import cx from 'clsx';
import * as React from 'react';

import * as styles from './index.css';
import { MenuButton } from './menu_button';
import { trackClickLayout, trackClickPhotos, trackClickVideo } from '../tracking';

export interface IControlsProps {
  isVideo: boolean;
  photoCount: number | undefined;
  layoutId: number | undefined;
  isCianLayout: boolean;
  isVideoPlay: boolean;
  isSingleGalleryCount: boolean;
  isInitialized: boolean;

  onElementShow(id: number): void;
}

export class Controls extends React.Component<IControlsProps, {}> {
  public render() {
    if (!this.props.isInitialized) {
      return null;
    }

    const className = this.props.isVideoPlay ? 'controls--hidden' : 'controls';

    return (
      <div className={cx(styles[className], { [styles['single']]: this.props.isSingleGalleryCount })}>
        {this.props.isVideo && (
          <MenuButton
            type="video"
            elementsCount={1}
            id={0}
            onButtonClick={(id: number) => {
              trackClickVideo();
              this.props.onElementShow(id);
            }}
          />
        )}
        {this.props.layoutId !== undefined && (
          <MenuButton
            type={this.props.isCianLayout ? 'cian-layout' : 'layout'}
            elementsCount={1}
            id={this.props.layoutId + Number(this.props.isVideo)}
            onButtonClick={(id: number) => {
              trackClickLayout();
              this.props.onElementShow(id);
            }}
          />
        )}
        {this.props.photoCount !== undefined && (
          <MenuButton
            type="photo"
            elementsCount={this.props.photoCount}
            id={Number(this.props.isVideo)}
            onButtonClick={(id: number) => {
              trackClickPhotos();
              this.props.onElementShow(id);
            }}
          />
        )}
      </div>
    );
  }
}
