import { connect } from 'react-redux';

import { Controls, IControlsProps } from './controls';
import {
  isCianLayoutSelector,
  isSingleGalleryCountSelector,
  isVideoSelector,
  layoutPositionIdSelector,
  photoCountSelector,
} from './utils/selectors';
import { showGalleryElement } from '../../../store/gallery';

import type { DispatchReduxAction, IState } from '../../../store';

type TStoreProps = Pick<
  IControlsProps,
  'isInitialized' | 'isSingleGalleryCount' | 'isVideo' | 'isVideoPlay' | 'layoutId' | 'photoCount' | 'isCianLayout'
>;

type TDispatchProps = Pick<IControlsProps, 'onElementShow'>;

export function mapStateToProps(state: IState): TStoreProps {
  return {
    photoCount: photoCountSelector(state),
    isSingleGalleryCount: isSingleGalleryCountSelector(state),
    isVideo: isVideoSelector(state),
    layoutId: layoutPositionIdSelector(state),
    isCianLayout: isCianLayoutSelector(state),
    isInitialized: state.gallery.isInitialized,
    isVideoPlay: state.gallery.isVideoPlayed,
  };
}

export function mapDispatchToProps(dispatch: DispatchReduxAction): TDispatchProps {
  return {
    onElementShow: (id: number) => {
      dispatch(showGalleryElement(id));
    },
  };
}

export const GalleryControlsContainer = connect<TStoreProps, TDispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(Controls);
