export enum EMediaType {
  Layout = 'layout',
  CianLayout = 'cianLayout',
  Photo = 'photo',
  Video = 'video',
  VideoKinescope = 'videoKinescope',
  Component = 'component',
  Tour = 'tour',
  VideoVk = 'videoVk',
}

interface IMedia<T extends EMediaType> {
  type: T;
  src: string;
  description?: string;
}

interface IMediaWithThumbnail extends IMedia<EMediaType.CianLayout | EMediaType.Layout | EMediaType.Photo> {
  thumbnailSrc: string;
}

interface IMediaComponent {
  type: EMediaType.Component;
  component: React.ReactNode;
  thumbnailSrc: string;
}

interface ITour {
  type: EMediaType.Tour;
  thumbnailSrc: string;
}

interface IMediaVideo<T extends EMediaType> {
  id: string;
  previewUrl: T extends EMediaType.Video ? undefined : string;
  src: string;
  type: T;
}

export type TMediaVideo = IMediaVideo<EMediaType.Video | EMediaType.VideoKinescope | EMediaType.VideoVk>;

export type TMedia = TMediaVideo | IMediaWithThumbnail | IMediaComponent | ITour;
