import { useSliderContext } from '@cian/slider';
import * as React from 'react';

import * as styles from './InfoControls.css';
import { Control } from './components/Control';
import { trackLayoutsClick, trackPhotosClick, trackVideosClick } from './tracking';
import { IInfoControlConfig } from './types';
import { EMediaType, TMedia } from '../../types';

interface IInfoControlsProps {
  media: TMedia[];
}

const InfoControlsComponent: React.FC<React.PropsWithChildren<IInfoControlsProps>> = ({ media }) => {
  const { scrollTo } = useSliderContext();

  const handleControlClick = React.useCallback(
    (config: IInfoControlConfig) => {
      scrollTo(config.firstIndex);

      switch (config.type) {
        case EMediaType.Layout:
          trackLayoutsClick();
          break;
        case EMediaType.Photo:
          trackPhotosClick();
          break;
        case EMediaType.Video:
          trackVideosClick();
          break;
      }
    },
    [scrollTo],
  );

  const controls = React.useMemo<IInfoControlConfig[] | null>(() => {
    const list: IInfoControlConfig[] = [];

    const videos = media.filter(
      m => m.type === EMediaType.Video || m.type === EMediaType.VideoKinescope || m.type === EMediaType.VideoVk,
    );

    if (videos.length > 0) {
      list.push({
        type: EMediaType.Video,
        label: videos.length === 1 ? 'Видео' : `${videos.length} видео`,
        firstIndex: media.indexOf(videos[0]),
      });
    }

    const layouts = media.filter(m => [EMediaType.Layout, EMediaType.CianLayout].includes(m.type));
    if (layouts.length > 0) {
      list.push({
        type: EMediaType.Layout,
        label: 'Планировка',
        firstIndex: media.indexOf(layouts[0]),
      });
    }

    const tour = media.filter(m => m.type === EMediaType.Tour);
    if (tour.length > 0) {
      list.push({
        type: EMediaType.Tour,
        label: '3D-тур',
        firstIndex: media.indexOf(tour[0]),
      });
    }

    const photos = media.filter(m => m.type === EMediaType.Photo);
    if (photos.length > 0) {
      list.push({
        type: EMediaType.Photo,
        label: photos.length === 1 ? 'Фото' : `${photos.length} фото`,
        firstIndex: media.indexOf(photos[0]),
      });
    }

    if (list.length === 0) {
      return null;
    }

    return list;
  }, [media]);

  return React.useMemo(() => {
    if (!controls) {
      return null;
    }

    return (
      <ul className={styles['container']}>
        {controls.map((c, i) => (
          <Control key={`control_${i}`} config={c} onClick={() => handleControlClick(c)} />
        ))}
      </ul>
    );
  }, [controls, handleControlClick]);
};

export const InfoControls = React.memo(InfoControlsComponent);
