import { IKPSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

let index: number | undefined;

export function getRandomKPPromo(kp: IKPSchema | null) {
  let promotionItem = null;

  if (kp && kp.promotions && kp.promotions.length > 0) {
    if (!index) {
      index = Math.floor(Math.random() * kp.promotions.length);
    }

    promotionItem = kp.promotions[index];
  }

  return promotionItem;
}
