import * as React from 'react';
import { useSelector } from 'react-redux';

import { MortgageBanner } from '../../../components/Gallery/components/MortgageBanner';
import { EMediaType, TMedia } from '../../../components/Gallery/types';
import { createFinMortgageFrame } from '../../../components/offer_gallery/createFinMortgageFrame';
import { MortgageFrame } from '../../../components/offer_gallery/createMortgageFrame';
import { useIsMortgageBannerAvailable } from '../../../hooks/mortgage/useIsMortgageBannerAvailable';
import { EOfferType } from '../../../repositories/offer-card/entities/offer/OfferSchema';
import { banksListSelector, selectMortgageGallery, selectOffer } from '../../../selectors';
import { selectMortgageRates } from '../../../selectors/mortgage/rates';

export const useMortgageBanner = (defaultThumbnailSrc: string): TMedia | null => {
  const { mortgageGalleryWidgetMarkup: mortgageBannerMarkup } = useSelector(selectMortgageGallery);
  const mortgageRates = useSelector(selectMortgageRates);
  const banksList = useSelector(banksListSelector);
  const { offerType } = useSelector(selectOffer);
  const isMortgageBannerAvailable = useIsMortgageBannerAvailable();

  const isSuburban = offerType === EOfferType.Suburban;

  return React.useMemo<TMedia | null>(() => {
    if (!isMortgageBannerAvailable) {
      return null;
    }

    let component: React.ReactNode = null;
    let thumbnailSrc = '';
    if (mortgageBannerMarkup) {
      const legacyFotoramaFrame = createFinMortgageFrame(mortgageBannerMarkup);
      component = <MortgageBanner innerHTML={legacyFotoramaFrame.html} />;
      thumbnailSrc = legacyFotoramaFrame.thumb;
    } else {
      component = <MortgageFrame banksList={banksList} isSuburban={isSuburban} rates={mortgageRates} />;
      thumbnailSrc = defaultThumbnailSrc;
    }

    return component && thumbnailSrc
      ? {
          type: EMediaType.Component,
          component,
          thumbnailSrc,
        }
      : null;
  }, [banksList, isMortgageBannerAvailable, isSuburban, mortgageBannerMarkup, mortgageRates, defaultThumbnailSrc]);
};
