import { ca } from '@cian/analytics';

import { getSourceParams } from 'shared/utils/getSourceParams';

export function trackFullscreenOpen() {
  const sourceParams = getSourceParams();

  ca('event', {
    action: 'Open_card_fullscreen_gallery',
    category: 'card',
    name: 'oldevent',
    page: {
      pageType: 'Card',
      siteType: 'desktop',
      extra: {
        pageTypeSource: sourceParams?.source,
        listingTypeSource: sourceParams?.listingType,
      },
    },
  });
}
