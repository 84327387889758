import * as React from 'react';
import { useSelector } from 'react-redux';

import { CallUnderDescriptionBanner } from 'shared/components/CallUnderDescriptionBanner';
import { CardSectionNew } from 'shared/components/CardSectionNew';
import { HintQuestion, HintQuestions } from 'shared/components/HintQuestions';
import { CallToAuthor } from 'shared/components/HintQuestions/CallToAuthor';
import { useChats } from 'shared/hooks/useChats';
import { useIsElectronicTradingOffer } from 'shared/hooks/useIsElectronicTradingOffer';
import { useRenderContactButtons } from 'shared/hooks/useRenderContactButtons';
import { IChatHintSchema } from 'shared/repositories/chats/entities/chat_hint/ChatHintSchema';
import { selectIsOfferPublished, selectOffer, selectUser } from 'shared/selectors';
import { offerChatSelector } from 'shared/selectors/offerChat';

import { PhonesContainer } from './parts';
import { trackHintQuestionClick } from './tracking';

export const HintQuestionsContainer: React.FC = () => {
  const { isChatsEnabled, hintQuestions } = useSelector(offerChatSelector);
  const offer = useSelector(selectOffer);
  const user = useSelector(selectUser);
  const isOfferPublished = useSelector(selectIsOfferPublished);
  const isElectronicTradingOffer = useIsElectronicTradingOffer();
  const { showPhoneButton } = useRenderContactButtons();
  const { handleOpenChat } = useChats();

  const onClick = React.useCallback(
    (text: string) => {
      const { analyticsKey, messageText } = hintQuestions.find(
        ({ buttonText }) => buttonText === text,
      ) as IChatHintSchema;

      trackHintQuestionClick(offer, analyticsKey);

      handleOpenChat({ defaultMessage: messageText });
    },
    [hintQuestions, offer, handleOpenChat],
  );

  if (
    isElectronicTradingOffer ||
    !isOfferPublished ||
    !isChatsEnabled ||
    user?.cianUserId === offer.cianUserId ||
    !hintQuestions.length
  ) {
    return null;
  }

  return (
    <CardSectionNew data-name="HintQuestionsContainer">
      <CallUnderDescriptionBanner title="Напишите автору" withoutButton={!showPhoneButton}>
        <HintQuestions>
          {hintQuestions.map(({ buttonText }, index) => (
            <HintQuestion key={index} onClick={onClick} text={buttonText} />
          ))}
        </HintQuestions>
        {showPhoneButton && <CallToAuthor phone={<PhonesContainer size="M" />} />}
      </CallUnderDescriptionBanner>
    </CardSectionNew>
  );
};
