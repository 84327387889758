import * as React from 'react';

import { GallerySidebarContainer } from 'shared/containers/GallerySidebar';

import * as styles from './Gallery.css';
import { GalleryFullscreen } from './components/GalleryFullscreen';
import { GalleryFullscreen as GalleryFullscreenRedesigned } from './components/GalleryFullscreenRedesigned';
import { GalleryInner } from './components/GalleryInner';
import { InfoControls } from './components/InfoControls';
import { TMedia } from './types';

interface IGallerySlots {
  slotFullscreenBefore?: React.ComponentProps<typeof GalleryFullscreen>['slotBefore'];
  slotScrollerAfter?: React.ReactElement;
}

interface IGalleryProps extends IGallerySlots {
  isFullscreenRedesign?: boolean;
  media: TMedia[];
  onFullscreenOpen?(): void;
}

const GalleryComponent: React.FC<React.PropsWithChildren<IGalleryProps>> = ({
  isFullscreenRedesign,
  slotScrollerAfter,
  slotFullscreenBefore,
  media,
  onFullscreenOpen,
}) => {
  const galleryInnerRef = React.useRef<React.ElementRef<typeof GalleryInner>>(null);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [isFullscreenOpen, setIsFullscreenOpen] = React.useState(false);

  /* istanbul ignore next */
  const handleSlideChange = React.useCallback((index: number) => {
    setCurrentSlide(index);
  }, []);

  /* istanbul ignore next */
  const handleFullscreenSlideChange = React.useCallback((index: number) => {
    galleryInnerRef.current?.scrollTo(index, { behavior: 'auto' });
    setCurrentSlide(index);
  }, []);

  const handleScrollerClick = React.useCallback(() => {
    setIsFullscreenOpen(true);

    if (onFullscreenOpen) {
      onFullscreenOpen();
    }
  }, [onFullscreenOpen]);

  const handleFullscreenClose = React.useCallback(() => {
    setIsFullscreenOpen(false);
  }, []);

  if (media.length === 0) {
    return <div className={styles['no-media']} />;
  }

  return (
    <div className={styles['container']}>
      <GalleryInner
        ref={galleryInnerRef}
        bordered
        fullscreenable
        keyboard={!isFullscreenOpen}
        media={media}
        objectFit="contain"
        withBackgroundBlur
        slotScrollerEnd={
          <>
            {slotScrollerAfter}
            <InfoControls media={media} />
          </>
        }
        onSlideChange={handleSlideChange}
        onScrollerClick={handleScrollerClick}
        currentSlide={currentSlide}
      />
      {!isFullscreenRedesign ? (
        <GalleryFullscreen
          slotBefore={slotFullscreenBefore}
          media={media}
          initialSlide={currentSlide}
          open={isFullscreenOpen}
          onSlideChange={handleFullscreenSlideChange}
          onClose={handleFullscreenClose}
          currentSlide={currentSlide}
        />
      ) : (
        <GalleryFullscreenRedesigned
          currentSlide={currentSlide}
          media={media}
          initialSlide={currentSlide}
          open={isFullscreenOpen}
          onSlideChange={handleFullscreenSlideChange}
          onClose={handleFullscreenClose}
          slotSidebar={<GallerySidebarContainer />}
        />
      )}
    </div>
  );
};

export const Gallery = React.memo(GalleryComponent);
