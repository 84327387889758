import { Label } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';

interface INewbuildingPromoLabelProps {
  texts: string[];
}

export const MortgagedPropertyLabels: React.FC<React.PropsWithChildren<INewbuildingPromoLabelProps>> = ({ texts }) => {
  return (
    <div className={styles['container']}>
      {texts.map((text, index) => (
        <div className={styles['label']} key={index}>
          <Label color="decorative-theme-dark" background="surface-neutral-default">
            {text}
          </Label>
        </div>
      ))}
    </div>
  );
};
