import { UIText1 } from '@cian/ui-kit';
import {
  IconProduct3DPlan16,
  IconMediaPhoto16,
  IconActionPlan16,
  IconMediaVideo16,
  IIconProps,
} from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './Control.css';
import { EMediaType } from '../../../../types';
import { IInfoControlConfig } from '../../types';

interface IControlProps {
  config: IInfoControlConfig;
  onClick?(): void;
}

const ICONS_MAP: { [key in IInfoControlConfig['type']]: React.ComponentType<React.PropsWithChildren<IIconProps>> } = {
  [EMediaType.Layout]: IconActionPlan16,
  [EMediaType.Photo]: IconMediaPhoto16,
  [EMediaType.Video]: IconMediaVideo16,
  [EMediaType.Tour]: IconProduct3DPlan16,
};

export const Control: React.FC<React.PropsWithChildren<IControlProps>> = ({ config, onClick }) => {
  const Icon = React.useMemo(() => ICONS_MAP[config.type], [config.type]);

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();

      if (onClick) {
        onClick();
      }
    },
    [onClick],
  );

  return (
    <li>
      <button className={styles['button']} onClick={handleClick}>
        <span className={styles['icon']}>
          <Icon display="block" color="icon-inverted-default" />
        </span>
        <UIText1 fontWeight="bold" color="text-inverted-default">
          {config.label}
        </UIText1>
      </button>
    </li>
  );
};
