import * as React from 'react';

import { AuthorSection } from 'shared/components/Gallery/components/AuthorSection';
import { GallerySidebarLayout } from 'shared/components/Gallery/components/GallerySidebar';
import { AuthorAsideContainer as AuthorAsideContainerNew } from 'shared/containers/AuthorAsideContainerNew';
import { ContactsButtonContainer } from 'shared/containers/ContactsButton';
import { FavoriteButtonContainer, PriceContainer } from 'shared/containers/GallerySidebar/parts';
import { DescriptionContainer } from 'shared/containers/GallerySidebar/parts/Description/Description';
import { PricePerSquare } from 'shared/containers/GallerySidebar/parts/PricePerSquare';
import { AgencyBrandingAsideCardContainer } from 'shared/containers/agencyBranding/AgencyBrandingAsideCard';

export const GallerySidebarContainer: React.FC = () => {
  return (
    <GallerySidebarLayout
      title={<DescriptionContainer />}
      favoriteButton={<FavoriteButtonContainer />}
      price={<PriceContainer />}
      pricePerSquare={<PricePerSquare />}
      buttons={<ContactsButtonContainer placeType="sidebar" />}
      author={
        <>
          <AuthorAsideContainerNew />
          <AuthorSection>
            <AgencyBrandingAsideCardContainer />
          </AuthorSection>
        </>
      }
    />
  );
};
