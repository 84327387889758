import { useEffect, RefObject } from 'react';

import { useApplicationContext } from 'shared/containers/ApplicationContext';

export const useVkPlayer = (iframeRef: RefObject<HTMLIFrameElement>) => {
  const { logger, telemetry } = useApplicationContext();

  useEffect(() => {
    telemetry.increment('VkPlayer.init');

    const iframe = iframeRef.current;

    if (!iframe) {
      return () => {};
    }

    const player = window.VK.VideoPlayer(iframe);

    player.on('inited', () => {
      telemetry.increment('VkPlayer.inited');
    });

    player.on('error', (error: unknown) =>
      logger.error('Vk player error', {
        error: JSON.stringify(error),
      }),
    );

    return () => player.destroy();
  }, [iframeRef, logger, telemetry]);
};
