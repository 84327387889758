import * as React from 'react';

import { useVkPlayer, useVkVideoLink } from './hooks';
import { TMediaVideo } from '../../types';

export const VkPlayer: React.FC<TMediaVideo> = props => {
  const src = useVkVideoLink(props.src);

  const iframeRef = React.useRef<HTMLIFrameElement>(null);

  useVkPlayer(iframeRef);

  return (
    <iframe
      src={src}
      allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
      frameBorder="0"
      width="100%"
      ref={iframeRef}
      data-testid="VkPlayer"
    />
  );
};
