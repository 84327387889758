import cx from 'clsx';
import * as React from 'react';

import * as styles from './Phones.css';

interface IPhone {
  link: string;
  number: string;
}

export interface IPhonesProps {
  phones: IPhone[];
}

export function Phones({ phones }: IPhonesProps) {
  return (
    <div data-name="CallToAuthorPhones">
      {phones.map((phone, index) => (
        <a key={index} href={phone.link} className={cx(styles['phone'], styles['phone-size-xs'])}>
          {phone.number}
        </a>
      ))}
    </div>
  );
}
