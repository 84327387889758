import { IconActionFullscreen16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './ButtonFullscreen.css';

export const ButtonFullscreen: React.FC = () => {
  return (
    <span className={styles['container']}>
      <IconActionFullscreen16 display="block" color="icon-inverted-default" />
    </span>
  );
};
