import * as React from 'react';

import { CallUnderDescriptionBanner } from 'shared/components/CallUnderDescriptionBanner';
import { CardSectionNew } from 'shared/components/CardSectionNew';

import { PhonesContainer } from './parts';

export function CallUnderDescriptionBannerContainer() {
  return (
    <CardSectionNew data-name="CallUnderDescriptionBannerContainer">
      <CallUnderDescriptionBanner subtitle="Вам ответят на все вопросы">
        <PhonesContainer size="M" />
      </CallUnderDescriptionBanner>
    </CardSectionNew>
  );
}
