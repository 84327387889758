import { connect } from 'react-redux';

import { IPromotionsLabelStoreProps, PromotionsLabel } from './PromotionsLabel';
import { getAnalyticsData } from '../../selectors';
import { IState } from '../../store';
import { getRandomKPPromo } from '../../utils/getRandomKPPromo';

export function mapStateToProps(state: IState): IPromotionsLabelStoreProps {
  const { kp } = state.offerData;

  return {
    promotionItem: getRandomKPPromo(kp),
    analyticsData: getAnalyticsData(state),
  };
}

export const CountrysidePromoLabelContainer = connect<IPromotionsLabelStoreProps>(mapStateToProps)(PromotionsLabel);
