import * as React from 'react';

import * as styles from './CianLayout.css';

interface ICianLayoutProps {
  src: string;
  deoptimised?: boolean;
}

export const CianLayout: React.FC<React.PropsWithChildren<ICianLayoutProps>> = ({ src, deoptimised }) => {
  return (
    <img
      src={src}
      className={styles['container']}
      loading={deoptimised ? undefined : 'lazy'}
      decoding={deoptimised ? undefined : 'async'}
    />
  );
};
