import { useMemo } from 'react';

import { useApplicationContext } from 'shared/containers/ApplicationContext';

export const useVkVideoLink = (baseUrl: string) => {
  const { config } = useApplicationContext();

  return useMemo(() => {
    const vkVideoQueryParams = config.get<string>('offerCard.vkVideoParams');

    return [baseUrl, vkVideoQueryParams].filter(Boolean).join('&');
  }, [baseUrl, config]);
};
