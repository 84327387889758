import { ca } from '@cian/analytics';

import { IAgentSchema, IOfferSchema, IUserSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { getCustomUrl } from '../../../../utils/tracking/getCustomUrl';
import { getPage } from '../../../../utils/tracking/getPage';
import { getPartner } from '../../../../utils/tracking/getPartner';
import { getUser } from '../../../../utils/tracking/getUser';
import { getProducts } from '../../../../utils/tracking/get_products';

const baseEvent = {
  name: 'oldevent',
};

export function trackShowPhones(
  offer: IOfferSchema,
  agent: IAgentSchema | null,
  user: IUserSchema | null,
  abGroup: number,
  kpId: number | null,
  siteBlockId?: number | null,
) {
  const products = getProducts({ agent, offer, kpId, siteBlockId });

  ca(
    'eventEbc',
    Object.assign(
      {
        action: 'Open_card_fullscreen_gallery',
        category: 'Phones',
        label: getCustomUrl(offer, agent),
        page: getPage(agent, offer, siteBlockId),
        partner: getPartner(user, offer),
        products: [
          {
            ...products,
            variant: products.variant ? products.variant.split('/') : [],
          },
        ],
        user: getUser(user, abGroup),
      },
      baseEvent,
    ),
  );
}
