import { UIText1 } from '@cian/ui-kit';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { ParentLinkContainer } from 'shared/containers/GallerySidebar/parts/Parent/ParentLink';
import { getFloorInfo } from 'shared/selectors/offerData/getFloorInfo';
import { selectOfferTitle } from 'shared/selectors/selectOfferTitle';

export const DescriptionContainer = () => {
  const title = useSelector(selectOfferTitle);
  const floors = useSelector(getFloorInfo);

  return (
    <UIText1>
      {title}, {floors} <ParentLinkContainer />
    </UIText1>
  );
};
